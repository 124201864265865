import React from 'react';

import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout/Layout';

import LeadForm from "../components/LeadForm";
import NewsletterSub from "../components/NewsletterSub";
import Button from '../components/Button';
import { Link } from 'gatsby';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Servicios especializados",
  desc:  "Nuestros servicios especializados son un complemento ideal a nuestra oferta de productos de limpieza para clientes institucionales con mayores exigencias y buscando tener un solo aliado que resuelva todas sus necesidaes de higiene institucional.",
  image: "servicios.jpg",
  pathname: "/servicios/",
}

 const Servicios = ({location}) =>  (

  <Layout location={location} crumbLabel="Servicios"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>




  <StaticImage src="../images/servicios.jpg"
              className="object-contain"
              alt="Servicios especializados de Unilimpio"
              placeholder="blurred"
              layout="fullWidth"
               />


<section id="presentacion" className="bg-white flex justify-center">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
    <h1 className="text-5xl font-semibold text-primary-lighter ">Servicios Especializados</h1>
           <p className="text-xl text-blue-900 mb-20 ">Nuestros servicios especializados son un complemento ideal a nuestra oferta de productos para clientes institucionales con mayores exigencias y buscando tener un solo aliado que resuelva todas sus necesidaes de higiene institucional.</p>
    </div>
    </div>
</section>





<section id="Somos fabricantes" className="mx-auto w-full ">
  <div className="bg-white flex flex-col md:flex-row">
   <div className="flex-1">
   <StaticImage src="../images/difusor-de-aroma.jpg"
                    className=" "
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"/>
    </div>
    <div className="flex-1 px-4 py-4 sm:px-6 lg:px-8 lg:py-2">
    
      <h2 className="text-2xl leading-6 font-medium text-secondary-lighter text-left sm:text-center">
      Servicio de Aromatización de Ambientes y Marketing Olfativo
      </h2>
      <p className="mt-4 text-lg text-blue-900 ">
      Nuestro compromiso: crear experiencias únicas para sus clientes y colaboradores. Seleccionamos fragancias premium, estratégicamente distribuidas para una experiencia olfativa envolvente. Ya sea relajante, energizante o sofisticado, personalizamos aromas que se adaptan perfectamente a sus deseos. Deje que sus espacios hablen a través de los sentidos, marcando una diferencia duradera.
      </p>
      <div className="pt-6 pb-8 px-6">
          <h3 className="text-lg font-medium text-primary-lighter  font-semibold tracking-wide uppercase">Incluye:</h3>
          <ul className="mt-6 space-y-4">
            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Dotación de dispensadores/difusores de fragancia.</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Servicio técnico para la instalación y configuración de los equipos.</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Asesoría para la selección y personalización de fragancias (cuando aplique).</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Revisión periódica de equipos y mantenimiento según sea necesario.</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Recarga de fragancia en equipos disensadores/difusores.</span>
            </li>

          </ul>
        </div>
         <Link to="/contactenos/">
          <Button className=" sm:text-center my-4 md:my-8 text-white text-extrabold text-xl  rounded-xl transition duration-200 ease-in-out bg-lime-500 shadow-md hover:bg-primary-lighter hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Solicitar Prueba Gratuita</Button>
         </Link>
      
    </div>
  </div>
</section>



<section id="Somos fabricantes" className="mx-auto w-full mt-20">
  <div className="bg-white flex flex-col md:flex-row">
   <div className="flex-1">
   <StaticImage src="../images/equipo-de-sanitizacion.jpg"
                    className="rigth"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"/>
    </div>
    <div className="flex-1 px-4 py-4 sm:px-6 lg:px-8 lg:py-2">
    
      <h2 className="text-2xl leading-6 font-medium text-secondary-lighter text-left">
      Servicio de Sanitización
      </h2>
      <p className="mt-4 text-lg text-blue-900 ">
      Nuestro sistema innovador inyecta una dosis precisa de líquido higienizador combatiendo bacterias y gérmenes, principales culpables de malos olores y manchas antiestéticas. Esta potente fórmula actúa durante 30 días, liberando una fragancia encantadora. No solo eliminamos olores, transformamos su baño en un espacio higiénico, fresco y acogedor, proporcionándole una experiencia impecable y duradera.
      </p>
      <div className="pt-6 pb-8 px-6">
          <h3 className="text-lg font-medium text-primary-lighter  font-semibold tracking-wide uppercase">Incluye:</h3>
          <ul className="mt-6 space-y-4">
            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Dotación de dispensadores automáticos para desinfección de baterias sanitarias.</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900">Servicio técnico para instalación y programación de los dispensadores.</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900 ">Revisión periódica y mantenimiento sin costo de dispensadores cuando sea requerido.</span>
            </li>

            <li className="flex space-x-3">

              <svg className="flex-shrink-0 h-5 w-5 text-secondary-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              <span className="text-base text-blue-900">Reposición periódica de solución desinfectante y desodorizante.</span>
            </li>
          </ul>
        </div>
         <Link to="/contactenos/">
          <Button className="my-4 md:my-8 text-white text-extrabold text-xl  rounded-xl transition duration-200 ease-in-out bg-lime-500 shadow-md hover:bg-primary-lighter hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Solicitar Prueba Gratuita</Button>
         </Link>
      
    </div>
  </div>
</section>































<NewsletterSub/>

  </Layout>
);

export default Servicios;
